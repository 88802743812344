import {getQualification} from "service.js";
import TronLinkClient from "WalletClient/TronlinkClient.js";
import Emitter from "utils/eventBus";

export const connect = async (dispatch) => {
        if (!TronLinkClient.isInstalled) {
            Emitter.emit("openMessageModal", {message: 'tronlink_not_install'});
            return;
        }
        if (!TronLinkClient.isLogin) {
            let data = await TronLinkClient.connect();
            if (data === 0) {
                Emitter.emit("openMessageModal", {message: 'tronlink_not_login'});
                return;
            }
            if (data === 1) {
                Emitter.emit("openMessageModal", {message: 'tronlink_not_connected'});
                return;
            }

            if (TronLinkClient.isLogin) {
                qualificationCheck(dispatch);
            }
            return;
        }
        // Emitter.emit("openAssetsModal");
        qualificationCheck(dispatch);
    };

    export const qualificationCheck = async (dispatch) => {

        let {inWhiteList, allowCount, claimSig, level, canMint, network} = await getQualification(TronLinkClient.tronWeb.defaultAddress.hex);
        dispatch({
            type: 'SET_ACCOUNT',
            account: {
                inWhiteList,
                allowCount,
                claimSig,
                level,
                canMint,
                network
            }
        });

        dispatch({
            type: 'SET_ADDRESS',
            tronAddress: TronLinkClient.tronWeb.defaultAddress.base58
        });
    };


