import {CONTRACT} from 'constants.js';

class TronLink {

    constructor() {
        this.isInstalled = false;
        this.isLogin = false;
        this.tronweb = null;
        setTimeout(() => {
            this.detection();
        }, 1000);
    }

    init() {
        this.tronWeb = window.tronWeb;
    }

    detection() {
        if (typeof window.tronWeb !== 'undefined') {
            console.log('Tronlink is installed!');
            this.isInstalled = true;
            this.init();
            if (window.tronWeb.defaultAddress.base58) {
                this.isLogin = true;
                console.log('Tronlink is login!');
            } else {
                this.isLogin = false;
                console.log('Tronlink is not login!');
            }
        } else {
            console.log('Tronlink is not installed!');
            console.log('Tronlink is not login!');
            this.isInstalled = false;
            this.isLogin = false;
        }
    }

    on(event, callback) {
        window.addEventListener('message', function (e) {
            if (e.data.message && e.data.message.action === event) {
                console.log(e.data.message);
                callback();
            }
        })
    }

    request(method, params, successCallback, errorCallback) {
        window.tronWeb.request({
            method: method,
            params: params,
        }).then((res) => {
            console.log(res);
            successCallback()
        }).catch((error) => {
            console.log(error);
            errorCallback();
        })
    }

    async connect() {
        try {
            let res = await window.tronLink.request({method: 'tron_requestAccounts'});
         
            if(!res){
                return 0 // not login
            }
            if (res.code !== 200) {
                return 1; // not connected
            } else {
                this.isLogin = true;
                return true; //connected
            }
        } catch (e) {
            return 0;
        }
    }

    addTokenToTronLink() {
        const tronWeb = window.tronWeb;
        const params = {
            type: 'trc721',
            options: {
                address: CONTRACT,
            },
        };
        tronWeb.request({
            method: 'wallet_watchAsset',
            params: params,
        })
    }

    async getContract() {
        try{
            let contract = await window.tronWeb.contract().at(CONTRACT);
            return contract;
        } catch(err) {
            console.log(err)
            debugger
        }
    }

    async getMintInfo() {
        try{
            let contract = await this.getContract();
            let claimedStats = await contract.claimedStats().call();
            let supplyLimit = await contract.supplyLimit().call();
            let claimedTimes = await contract.claimedTimes().call();
            let unFormatted = {
                claimedStats,
                supplyLimit,
                claimedTimes
            };
            return unFormatted;
        } catch(err) {
            console.log(err)
        }
       
    }

    async claim(level, count, signature) {
        let contract = await this.getContract();
        let data = await contract.claim(level, count, signature).send({callValue: 50000000});
        let unFormatted = data;
        return unFormatted
    }

    async commonClaim() {
        let contract = await this.getContract();
        let data = await contract.commonClaim().send({callValue: 50000000});
        let unFormatted = data;
        return unFormatted
    }

}

const TronLinkClient = new TronLink();

export default TronLinkClient;