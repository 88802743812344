/*eslint-disable*/
import React, {useEffect, useContext} from "react";
import {Link} from "react-router-dom";
import {mainContext} from 'reducer'
import LangToggle from "components/Toggles/LangToggle.js";
import TronLinkClient from "WalletClient/TronlinkClient.js";
import {connect, qualificationCheck} from "components/Wallet.js";
import {t} from "utils/text.js";
import {Truncate} from "utils/text.js"
import Emitter from "utils/eventBus";

export default function Navbar() {

    const {dispatch, state} = useContext(mainContext);
    const {account, tronAddress} = state;

    useEffect(() => {

        const set = function (data) {
            if (data.data.message) {
                if (data.data.message.action == 'setAccount' || data.data.message.action == 'accountsChanged' || data.data.message.action == 'disconnect') {
                    Emitter.emit('closeModel')
                    TronLinkClient.detection();
                    if (TronLinkClient.isLogin) {
                        qualificationCheck(dispatch);
                    } else {
                        dispatch({
                            type: 'SET_ACCOUNT',
                            account: {
                                inWhiteList: false,
                                allowCount: 0,
                                claimSig: '',
                                level: 0,
                                canMint: false,
                                network: TronLinkClient.tronWeb.fullNode.host === 'https://api.trongrid.io' ? 'main_net' : 'other'
                            }
                        });

                        dispatch({
                            type: 'SET_NFT',
                            nft: {
                                tokenId: "",
                                amount: 0,
                                level: 0
                            }
                        });

                        dispatch({
                            type: 'SET_ADDRESS',
                            tronAddress: ''
                        });


                    }
                }
            }
        };

        window.addEventListener('message', set);

        return () => {
            window.removeEventListener('message', set);
        }

    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (TronLinkClient.isLogin) {
                qualificationCheck(dispatch);
            }
        }, 1500)
    }, []);


    return (
        <>
            <nav className="top-0 z-40 w-full flex flex-wrap items-center justify-between transparent fixed">
                <div className="_container w-full flex flex-wrap items-center justify-between">
                    <div>
                        {/* <Link to="/" className=" leading-relaxed inline-block">
                            <img className='header_img' src={require('../../assets/img/logo.png').default}/>
                        </Link> */}
                    </div>
                    <div>
                        <div className="flex items-center list-none">
                            <div>
                                <LangToggle/>
                            </div>
                            <div>
                                {tronAddress ? <button className="text-center flex items-center justify-center outline-none focus:outline-none" type="button">
                                    <Truncate>{tronAddress}</Truncate>
                                </button> : <button className="text-center flex items-center justify-center outline-none focus:outline-none" type="button" onClick={()=>{connect(dispatch)}}>
                                    <img className='wallet_img' src={require('../../assets/img/wallet.png').default}/> <span>{t('wallet_connect')}</span>
                                </button>}
                                {tronAddress && account.network === 'other' && <div className="not_main">{t('not_main_net')}</div>}
                                {/* <button className="text-center flex items-center justify-center outline-none focus:outline-none" type="button" onClick={()=>{connect(dispatch)}}>
                                    <img className='wallet_img' src={require('../../assets/img/wallet.png').default}/> <span>{t('wallet_connect')}</span>
                                </button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}
