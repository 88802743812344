import React, {useEffect, useRef} from "react";
import Emitter from "utils/eventBus";
import {t} from "utils/text.js";

export default function ErrorModal() {

    const [showModal, setShowModal] = React.useState(false);
    const message = useRef(null);

    useEffect(() => {
        const set = function (params) {
            message.current = params.message;
            openModal();
        };
        Emitter.on("openErrorModal", set);
        Emitter.on("closeModel", closeModal);
        return () => {
            Emitter.removeListener('closeModel', closeModal);
            Emitter.removeListener("openErrorModal", set);
            document.getElementsByTagName('body')[0].style.overflow = '';
        }
    }, []);

    const openModal = () => {
        setShowModal(true);
        document.getElementsByTagName('body')[0].style.overflow= 'hidden';
    };

    const closeModal = () => {
        setShowModal(false);
        document.getElementsByTagName('body')[0].style.overflow = '';
    };

    return (
        <>
            {showModal ? (
                <>
                    <div className="fixed flex top-0 w-2/3 z-100 md:w-1/4 left-0 right-0 mx-auto my-auto top-0 bottom-0"
                         style={{height: '300px'}}>
                        <button
                            className="absolute right-0 bg-transparent mr-4 font-semibold outline-none focus:outline-none text-blueGray-400"
                            style={{fontSize:'25px'}}
                            onClick={closeModal}
                        >
                            <span>×</span>
                        </button>
                        <div className="w-full ErrorModal">
                            {/*content*/}
                            <div className="p-30-px flex flex-col justify-center rounded-lg bg-white overflow-auto">

                                <img className="error-img" src={require(`assets/img/icon-error.png`).default} alt="error"  />

                                <p className='font-semibold mt-30-px fs-20-px'>{t('fail_to_claim')}</p>

                                <p className="mt-20-px">
                                    {t(message.current)}
                                </p>

                            </div>
                        </div>
                    </div>
                    <div className="bg-opacity-50 bg-black fixed top-0 left-0 w-full h-full inset-0 z-50"></div>
                </>
            ) : null}
        </>
    );
}