import { useState, useEffect, useContext } from "react";
import { mainContext } from 'reducer'
import { claim, commonClaim, getTXStatus, currentRound, getAvailable } from "service.js";
import Emitter from "utils/eventBus";
import {t} from "utils/text.js"
import { connect, qualificationCheck } from "components/Wallet.js";

export default function Page2() {
    const { state, dispatch } = useContext(mainContext);
    const { account, tronAddress } = state;
    const [loading, setLoading] = useState(false);
    const [current, setCurrent] = useState(0);
    const [available, setAvailable] = useState('--');
    const [limt, setLimt] = useState('--');

    useEffect(() => {
        getCurrentRound()
        getAvailableData()
    }, [])

    const handleMint = async () => {
        if (!tronAddress) {
            connect(dispatch)
            return
        }
        if (loading) return;
        if (account.canMint) {
            if (account.inWhiteList) {
                setLoading(true);
                let result = await claim(account.level, account.allowCount, account.claimSig);
                if (result && result['error']) {
                    setLoading(false);
                    Emitter.emit("openErrorModal", { message: result.message });
                } else {
                    setTimeout(async () => {
                        getTxRes(result)
                    }, 10000);
                }
            } else {
                setLoading(true);
                let result = await commonClaim();
                if (result && result['error']) {
                    setLoading(false);
                    Emitter.emit("openErrorModal", { message: result.message });
                } else {
                    setTimeout(async () => {
                        getTxRes(result)
                    }, 10000);
                }
            }
        } else {
            Emitter.emit("openFeedbackModal", { message: 'not_num', type: 'error' });
        }
    }

    const getTxRes = async (result) => {
        let verify_hash = await getTXStatus(result);
        if (verify_hash === 'SUCCESS') {
            setLoading(false);
            getAvailableData()
            qualificationCheck(dispatch)
            Emitter.emit("openFeedbackModal", { message: 'mint_success' });
        } else {
            Emitter.emit("openErrorModal", { message: 'fail_to_claim' });
        }
        setLoading(false);
    }

    const getCurrentRound = async (result) => {
        let currentRoundData = await currentRound();
        setCurrent(currentRoundData)
    }

    const getAvailableData = async () => {
        let data = await getAvailable();
        setAvailable(data.availableNum)
        setLimt(data.limtNum)
    }

    // DOM to render
    return (
        <div className="page2-warp" id="page2">
            <a className="btn-warp" href="https://apenft.io/collections/tronstar" target="_blank" rel="noreferrer">
                <div className="view-text">
                    <img className="icon-cart" src={require('assets/img/icon-cart.png').default} alt="cart" />
                    <span>{t("view_btn")}</span>
                </div>
                <img src={require('assets/img/view-btn.png').default} alt="view" />
            </a>


            {available !== 0 && available !== '--'  && <div className="btn-warp mt-10-px" onClick={handleMint}>
                {!loading && <>
                    <div className="view-text">
                        <span className="main-color">{t('mint_now')}</span>
                        <img className="icon-go" src={require('assets/img/icon-go.png').default} alt="go" />
                    </div>
                    <img src={require('assets/img/mint-btn.png').default} alt="mint" />
                </>}
                {loading && <>
                    <div className="view-text">
                        <span className="main-color">{t('minting')}</span>
                        <img className="icon-go clock" src={require('assets/img/icon-clock.png').default} alt="go" />
                    </div>
                    <img src={require('assets/img/mint-btn.png').default} alt="mint" />
                </>}
            </div>}

            {(available === 0 || current === 0) && available !== '--' && <div className="btn-warp">
                <div className="view-text">
                    <span className="main-color">{current === 0 ? "Coming soon" : t("sold_out")}</span>
                </div>
                <img src={require('assets/img/sold-out.png').default} alt="view" />
            </div>}

            <div className="flex flex-row justify-center mt-20-px fs-14-px phone-flex-clo">
                <div className="flex items-center mr-20-px">
                    <span>{t("mint_price")} &nbsp;</span>
                    <span className="pr-10-px">50 TRX</span>
                    <img className="icon-trx" src={require('assets/img/icon-trx.png').default} alt="img" />
                </div>

                <div className="flex items-center ph-mt-10-px">
                    <span>{t("available")} &nbsp;</span>
                    <span className="pr-10-px">{available}/{limt}</span>
                    <img className="icon-img" src={require('assets/img/icon-img.png').default} alt="img" />
                </div>
            </div>

            <ul className="rule-warp mt-90-px fs-14-px">
                <li className="flex items-center justify-start">
                    {current === 1 ?
                        <img className="icon-current" src={require('assets/img/icon-current.png').default} alt="current" /> :
                        <div className="num flex items-center justify-center">1</div>
                    }
                    <div>{t('rule_1')}</div>
                </li>
                <li className="flex items-center justify-start mt-10-px">
                    {current === 2 ?
                        <img className="icon-current" src={require('assets/img/icon-current.png').default} alt="current" /> :
                        <div className="num flex items-center justify-center">2</div>
                    }
                    <div>{t('rule_2')}</div>
                </li>
                <li className="flex items-center justify-start mt-10-px">
                    {current === 3 ?
                        <img className="icon-current" src={require('assets/img/icon-current.png').default} alt="current" /> :
                        <div className="num flex items-center justify-center">3</div>
                    }
                    <div>{t('rule_3')}</div>
                </li>
            </ul>

            <div className="fs-14-px font-normal mt-10-px">
                {t("total_supply")}: 6600
            </div>


            <div className="cooperation-warp text-center">
                <div className="flex items-center">
                    <a href="https://apenft.io/" target="_blank" rel="noreferrer">
                        <img className="icon-ape" src={require('assets/img/ape.png').default} alt="img" />
                    </a>
                    <img className="icon-x" src={require('assets/img/icon-x.png').default} alt="img" />
                    <a href="https://tron.network/" target="_blank" rel="noreferrer">
                        <img className="icon-tron" src={require('assets/img/tron.png').default} alt="img" />
                    </a>
                </div>
                <div className="fs-14-px font-normal">
                    {t("partners")}
                </div>
            </div>
            

            <img className="head-star" src={require('assets/img/star-black.png').default} alt="star" />

        </div>
    );
}