import React, {useEffect, useRef} from "react";
import Emitter from "utils/eventBus";
import {t} from "utils/text.js";

export default function FeedbackModal() {

    const [showModal, setShowModal] = React.useState(false);
    const [type, setType] = React.useState('success');
    const message = useRef(null);

    useEffect(() => {
        const set = function (params) {
            message.current = params.message;
            setType(params.type || 'success')
            openModal();
        };
        Emitter.on("openFeedbackModal", set);
        Emitter.on("closeModel", closeModal);
        return () => {
            Emitter.removeListener("closeModel", closeModal);
            Emitter.removeListener('openFeedbackModal', set);
            document.getElementsByTagName('body')[0].style.overflow = '';
        }
    }, []);

    const openModal = () => {
        setShowModal(true);
        document.getElementsByTagName('body')[0].style.overflow= 'hidden';
    };

    const closeModal = () => {
        setShowModal(false);
        document.getElementsByTagName('body')[0].style.overflow = '';
    };

    return (
        <>
            {showModal ? (
                <>
                    <div className="fixed flex w-2/3 z-100 md:w-1/4 left-0 right-0 mx-auto my-auto top-0 bottom-0 items-center">
                        <button
                            className="absolute right-0 bg-transparent mr-5 font-semibold outline-none focus:outline-none text-blueGray-400"
                            style={{fontSize:'25px'}}
                            onClick={closeModal}
                        >
                            <span>×</span>
                        </button>
                        <div className="w-full FeedbackModal">
                            {/*content*/}
                            <div className="h-full flex justify-center items-center rounded-lg bg-white overflow-auto">
                            {/* ${type === 'success' ? 'success-text' : 'error-text'} */}
                                <img className="type-img" src={require(`assets/img/${type}.png`).default} alt="error"  />
                                <p className="p-8 fs-18-px font-semibold">
                                    {t(message.current)}
                                </p>

                            </div>
                        </div>
                    </div>
                    <div className="bg-opacity-50 bg-black fixed top-0 left-0 w-full h-full inset-0 z-50"></div>
                </>
            ) : null}
        </>
    );
}