import tronWeb from "tronweb";
import Client from "APIClient/APIClient.js";
import TronLinkClient from "WalletClient/TronlinkClient.js";
import { CONTRACT, TRONGRIDAPI, KEY } from 'constants.js';


const LocalTronWeb = new tronWeb({
    fullNode: TRONGRIDAPI,
    solidityNode: TRONGRIDAPI,
    eventServer: TRONGRIDAPI,
    privateKey: KEY
});

export const getQualification = async (address) => {
    try {
        let toDecimal = LocalTronWeb.toDecimal;

        let fortune = await Client.getWhitelist('0x' + address);
        let data = await TronLinkClient.getMintInfo();

        const { level, allow_count, claim_sig } = fortune.data || {}
        let { claimedStats, supplyLimit, claimedTimes } = data
        let canMint = false
        let inWhiteList = false

        const remainingNum = {
            epicCount: toDecimal(supplyLimit['epicCount']['_hex']) - toDecimal(claimedStats['epicCount']['_hex']),
            ordinaryCount: toDecimal(supplyLimit['ordinaryCount']['_hex']) - toDecimal(claimedStats['ordinaryCount']['_hex']),
            rareCount: toDecimal(supplyLimit['rareCount']['_hex']) - toDecimal(claimedStats['rareCount']['_hex'])
        }

        const mintTimes = toDecimal(claimedTimes['_hex'])

        // 1: ordinaryCount，2: rareCount，3:epicCount

        if (level === 1 || fortune.code === 2) {
            canMint = remainingNum.ordinaryCount > 0 && mintTimes < 10
        } else if (level === 2) {
            canMint = remainingNum.rareCount > 0 && mintTimes < allow_count
        } else {
            canMint = remainingNum.epicCount > 0
        }

        if (fortune.code === 0) {
            inWhiteList = true
        }
        return {
            inWhiteList,
            allowCount: allow_count,
            claimSig: claim_sig,
            level,
            canMint,
            network: TronLinkClient.tronWeb.fullNode.host === 'https://api.trongrid.io' ? 'main_net' : 'other'
        }
    } catch (e) {
        console.log(e)
        return {
            inWhiteList: false,
            allowCount: 0,
            claimSig: '',
            level: 0,
            canMint: false,
            network: TronLinkClient.tronWeb.fullNode.host === 'https://api.trongrid.io' ? 'main_net' : 'other'
        }
    }
};

export const claim = async (level, count, signature) => {
    try {
        let hash = await TronLinkClient.claim(level, count, signature);
        return hash;
    } catch (e) {
        console.log(e)
        return {
            "error": true,
            "message": 'trigger_contract_failed'
        }
    }
};

export const commonClaim = async () => {
    try {
        let hash = await TronLinkClient.commonClaim();
        return hash;
    } catch (e) {
        return {
            "error": true,
            "message": 'trigger_contract_failed'
        }
    }
};

export const getAvailable = async () => {
    try {
        let contract = await LocalTronWeb.contract().at(CONTRACT);
        let supplyLimit = await contract.supplyLimit().call({ _isConstant: true });
        let claimedStats = await contract.claimedStats().call({ _isConstant: true });

        let toDecimal = LocalTronWeb.toDecimal;

        const limtNum = toDecimal(supplyLimit['epicCount']['_hex']) + toDecimal(supplyLimit['ordinaryCount']['_hex']) + toDecimal(supplyLimit['rareCount']['_hex'])

        const remainingNum = toDecimal(claimedStats['epicCount']['_hex']) + toDecimal(claimedStats['ordinaryCount']['_hex']) + toDecimal(claimedStats['rareCount']['_hex'])

        return {
            availableNum: limtNum - remainingNum,
            limtNum
        }
    } catch (e) {
        console.log(e)
        return '--'
    }
};

export const currentRound = async () => {
    try {
        let contract = await LocalTronWeb.contract().at(CONTRACT);
        let data = await contract.currentRound().call({ _isConstant: true });

        let toDecimal = LocalTronWeb.toDecimal;
        return toDecimal(data['_hex'])
    } catch (e) {
        console.log(e)
    }
};

export const getTXStatus = async (hash) => {
    try {
        let { contractRet } = await Client.getTXStatus(hash);
        return contractRet;
    } catch (e) {
        console.log(e);
        return null;
    }
};



