import { useRef, useLayoutEffect, useEffect, useState } from "react";
import { gsap } from "gsap";
import { map, lerp, getMousePos, calcWinsize, getRandomNumber } from 'utils/utils.js';
import { t } from "utils/text.js"

let winsize = calcWinsize();
window.addEventListener('resize', () => winsize = calcWinsize());

let mousepos = {x: winsize.width/2, y: winsize.height/2};
window.addEventListener('mousemove', ev => mousepos = getMousePos(ev));

export default function Gsap() {
    const gaspRef = useRef(null);
    const boxRef1 = useRef(null);
    const boxRef2 = useRef(null);
    const boxRef3 = useRef(null);
    const boxRef4 = useRef(null);
    const boxRef5 = useRef(null);
    const boxRef6 = useRef(null);
    const boxRef7 = useRef(null);
    const boxRef8 = useRef(null);
    const boxRef9 = useRef(null);
    const boxRef10 = useRef(null);

    const refRandom = useRef(0)

    const imageList = useRef([1,2,3,4,5,6,7,8,9,10])
    const [img, setImg] = useState([1,2,3,4,5,6,7,8,9,10])
    const refArr = [boxRef1, boxRef2, boxRef3, boxRef4, boxRef5, boxRef6, boxRef7, boxRef8, boxRef9, boxRef10]

    useEffect(()=> {
        let timer = null
        let imgRandom = 0
        timer = setInterval(() => {
            imgRandom = getRandomNumber(11, 60)
            refRandom.current = getRandomNumber(0, 9)
            imageList.current[refRandom.current] = imgRandom

            setImg([...imageList.current])

        }, 3000)

        return () => {
            clearInterval(timer)
            timer = null
        };
    }, [])

    
    useLayoutEffect(() => {
        let gridItems = []
        let refArrCurrent = [boxRef1.current, boxRef2.current, boxRef3.current, boxRef4.current, boxRef5.current, boxRef6.current, boxRef7.current, boxRef8.current, boxRef9.current, boxRef10.current]
        gsap
            .timeline()
            .set(refArrCurrent, { scale: 0.7, opacity: 0 }, 0)
            .to(refArrCurrent, {
                duration: 2,
                ease: 'Expo.easeOut',
                scale: 1,
                stagger: { amount: 0.6, grid: 'auto', from: 'center' }
            }, 0)
            .to(refArrCurrent, {
                duration: 3,
                ease: 'Power1.easeOut',
                opacity: 0.4,
                stagger: { amount: 0.6, grid: 'auto', from: 'center' }
            }, 0);

            refArrCurrent.forEach(item => gridItems.push(move(item)));
    }, []);

    const move = (item) => {
        let translationVals = {tx: 0, ty: 0, r: 0};
        const xstart = getRandomNumber(15,60);
        const ystart = getRandomNumber(30,90);
        const randR = getRandomNumber(-15,15);

        const render = () => {
            translationVals.tx = lerp(translationVals.tx, map(mousepos.x, 0, winsize.width, -xstart, xstart), 0.07);
            translationVals.ty = lerp(translationVals.ty, map(mousepos.y, 0, winsize.height, -ystart, ystart), 0.07);
            translationVals.r = lerp(translationVals.r, map(mousepos.x, 0, winsize.width, -randR, randR), 0.07);
            
            gsap.set(item, {
                x: translationVals.tx, 
                y: translationVals.ty,
                rotation: translationVals.r,
            });
            requestAnimationFrame(render);
        }
        requestAnimationFrame(render);
    }

    // DOM to render
    return (
        <div className="page1-warp">

            <div className="grid-warp" ref={gaspRef}>
                {refArr.map((item, index) => (
                    <img key={index} className={`image-${index + 1}`} ref={item} src={require(`assets/img/${img[index]}.png`).default} alt="star" />
                ))}
            </div>

            <div className="page1-modal"></div>

            <a className="down-warp cursor-pointer" href="#page2">
                <p>{t('mint_now')}</p>
                <img className="icon-down" width="24px" height="28px" src={require(`assets/img/icon-down.png`).default} alt="down" />
            </a>

            <img className="tron-star" src={require('assets/img/tron-star.png').default} alt="tron-star" />

            <img className="head-star" src={require('assets/img/star-or.png').default} alt="star" />
        </div>
    );
}