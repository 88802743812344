/*eslint-disable*/
import React, { useState, useEffect, useContext } from "react";
import IndexNavbar from "components/Navbars/IndexNavbar.js";
import MessageModal from "components/Modals/MessageModal.js";
import ErrorModal from "components/Modals/ErrorModal.js";
import FeedbackModal from "components/Modals/FeedbackModal.js";
import Page1 from "components/Page1/index.js"
import Page2 from "components/Page2/index.js"
// import FullPage from "components/FullPage/index.js";

export default function Index() {


    return (
        <>
            <IndexNavbar />

            <Page1 />
            <Page2 />

            <MessageModal />
            <ErrorModal />
            <FeedbackModal />

            {/*<Fixed />*/}
        </>
    );
}
