import xhr from "axios/index";
import {APIURL, TRONSCANAPI} from "constants.js";

class APIClient {
    constructor() {
        this.apiUrl = APIURL;
        this.request = async (url, body, config) => {
            return new Promise(async (resolve, reject) => {
                try {
                    let {data} = await xhr.post(
                        this.apiUrl + url,
                        {
                            ...body
                        },
                        {...config}
                    );

                    resolve(data);
                }
                catch (e) {
                    resolve({
                        Type: 'error',
                        Message: e.response
                    });
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }

    async getTXStatus(hash) {
        let {data} = await xhr.get(TRONSCANAPI + '/api/transaction-info?hash=' + hash);
        return data;
    }

    getWhitelist(address) {
        return this.request('/api/v1/star_nft/sign?address=' + address);
    }

}

const Client = new APIClient();

export default Client;