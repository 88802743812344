const en_US = {
  'login_to_claim': 'Connect your TronLink to mint your NFT.',
  'no_tronlink': 'Don\'t have TronLink?',
  'tronlink_not_login': 'TronLink not Login',
  'tronlink_not_connected': 'TronLink not Connected',
  'tronlink_not_install': 'TronLink not Installed',
  'install': 'Install',
  'fail_to_claim': 'Mint Error',
  'token': 'Token',
  'not_main_net': 'Wrong network, please connect to TRON Mainnet',
  'trigger_contract_failed': 'Trigger Contract Failed',
  'contract_address': 'Contract Address: ',
  'refresh_to_login_1': 'If you have already logged into TronLink,',
  'refresh_to_login_2': 'please refresh the page to connect.',
  'wallet_connect': 'Connect',

  'mint_now': 'Mint Now !',
  'view_btn': 'View the collection on APENFT',
  'minting': 'Minting...',
  'available': 'Available',
  'rule_1': 'First round：July 15th 15:00 UTC+8, 1000 TRON STAR release.',
  'rule_2': 'Second round：July 22th 15:00 UTC+8, 2000 TRON STAR release.',
  'rule_3': 'Third round：August 9th 15:00 UTC+8, 3600 TRON STAR release.',
  'sold_out': 'Sold Out',
  'not_num': 'Mint limit reached',
  'mint_success': "Mint Success !",
  "mint_price": "Mint Price",
  "total_supply": "Total Supply",
  "partners": "Partners"
  
};

export default en_US