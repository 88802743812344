import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter, Route, Switch, Redirect} from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import 'assets/styles/index.scss';


import {ContextProvider} from './reducer'
import Inter from './locale/intl';

import Index from "views/Index.js";

ReactDOM.render(
    <ContextProvider>
        <Inter>
            <BrowserRouter>
                <Switch>
                  <Route path="/" exact component={Index} />
                  <Redirect from="*" to="/" />
                </Switch>
            </BrowserRouter>
        </Inter>
    </ContextProvider>,
    document.getElementById("root")
);



