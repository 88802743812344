import React from "react";
import { FormattedMessage } from "react-intl";

export function Truncate({children}) {
  if (children.length <= 14) {
    return (
        <div className="">
         {children}
        </div>
    )
  }
  if (children.length > 14)
    return (
        <div className="">
            {children.substring(0,6)} ... {children.substring(children.length - 4, children.length)}
        </div>
    )
}

export function t(id) {
  return <FormattedMessage textComponent="option" id={id} />;
}

export function toThousands(num) {
    if(num === null)
        return '--';
    return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
}

