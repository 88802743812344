export const RANKENUM = {
  0: 'no_rank',
  1: 'regular',
  2: 'rare',
  3: 'epic',
  4: 'legendary',
};

export const LEVEL_TO_IMG = {
  0: 'no_rank',
  1: 'ordinary',
  2: 'rare',
  3: 'epic',
  4: 'legend',
};

// main

export const CONTRACT = 'TYW2Su2ALXM1Nqf2NW3n6UgM5gGyYW9Exu';

export const TRONGRIDAPI = 'https://api.trongrid.io';

export const TRONSCANAPI = 'https://apilist.tronscan.org';

export const APIURL = 'https://www-backend-badge.btfs.io';

export const TRONSCAN = 'https://tronscan.io';

export const KEY = 'DFC4E185CBAA0377CC073B607C41D0DB0BBB2B3CC43218B89C858909DCC4C8DC';


// test

// export const CONTRACT = 'TDczyk1W3jNf62cfeZ5d7CasiVXfwy4xiY';

// export const TRONGRIDAPI = 'https://api.shasta.trongrid.io';

// export const TRONSCANAPI = 'https://shastapi.tronscan.org';

// export const APIURL = 'https://www-backend-badge-dev.btfs.io';

// export const TRONSCAN = 'https://tronscan.io';

// export const KEY = 'AC53A007212555659150D808E4B79A690198A154B97F6D4752BFBBA94926E684';