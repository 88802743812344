const zh_CN = {
  'login_to_claim': '请连接TronLink来铸造您的NFT。',
  'no_tronlink': '没有TronLink？',
  'tronlink_not_login': '未登录TronLink',
  'tronlink_not_connected': '未连接TronLink',
  'tronlink_not_install': '未安装TronLink',
  'install': '安装',
  'fail_to_claim': '铸造失败',
  'token': '通证',
  'not_main_net': '网络错误，请连接至TRON主网',
  'trigger_contract_failed': '触发合约失败',
  'refresh_to_login_1': '如果您已经登录您的TronLink,',
  'refresh_to_login_2': '请刷新本页面来连接您的TronLink钱包。',
  'wallet_connect': '连接钱包',

  'mint_now': 'Mint Now !',
  'view_btn': '在APENFT查看合集',
  'minting': 'Minting...',
  'available': '剩余',
  'rule_1': '第一轮：7月15日 15:00 UTC+8, 1000 TRON STAR release.',
  'rule_2': '第二轮：7月22日 15:00 UTC+8, 2000 TRON STAR release.',
  'rule_3': '第三轮：8月9日 15:00 UTC+8, 3600 TRON STAR release.',
  'sold_out': 'Sold Out',
  'not_num': '已达mint上限',
  'mint_success': "Mint Success !",
  "mint_price": "Mint价格",
  "total_supply": "总量",
  "partners": "Partners"
};

export default zh_CN