import React, {useReducer} from 'react'

const CHANGE_LOCALE = 'CHANGE_LOCALE';
const CHANGE_THEME = 'CHANGE_THEME';
const SET_ACCOUNT = 'SET_ACCOUNT';
const SET_NFT = 'SET_NFT';
const SET_ADDRESS = 'SET_ADDRESS';

const mainContext = React.createContext();

const initState = {
    locale: 'en',
    theme: 'light',
    tronAddress: '',
    account: {
        inWhiteList: false,
        allowCount: 0,
        claimSig: '',
        level: 0,
        canMint: false,
        network: null,
    },
    nft: {
        tokenId: "",
        amount: 0,
        level: 0
    }
};

const reducer = (state, action) => {
    switch (action.type) {
        case CHANGE_LOCALE:
            return {...state, locale: action.locale || 'en'};
        case CHANGE_THEME:
            return {...state, theme: action.theme || 'light'};
        case SET_ADDRESS:
            return {...state, tronAddress: action.tronAddress};
        case SET_ACCOUNT:
            return {
                ...state, account: action.account || {
                    inWhiteList: false,
                    allowCount: 0,
                    claimSig: '',
                    level: 0,
                    canMint: false,
                    network:null
                }
            };
            case SET_NFT:
                return {
                    ...state, nft: action.nft || {
                        tokenId: "",
                        amount: 0,
                        level: 0
                    }
                };
        default:
            return state
    }
};

const ContextProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, initState);
    return (
        <mainContext.Provider value={{state, dispatch}}>
            {props.children}
        </mainContext.Provider>
    )
};

export {reducer, mainContext, ContextProvider}
